/*** STYLES HOME IMPORT ***/
/*!** _common/__common.less - STYLES COMMUNS IMPORT ***/
/*************** LIBRAIRIEs *****************/
/*****************************************
Inline-grid system import
******************************************/
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
/** ROW  *****************************************************/
/** WARNING : the gutter must be the same as the column
/** @gutter : column spacing (px)
/*************************************************************/
/** COLUMN ***************************************************/
/** @int : desired columns, like bootstrap (int).
/** @gutter : column spacing (px)
/*************************************************************/
/** RESPONSIVE COLUMN *********************************************/
/** @int : desired columns, like bootstrap (int).
/** @responsive : screen width where the column will be 100% (px)
/** @gutter : column spacing (px)
/******************************************************************/
/** BECOME COL************************************************/
/** Use only if a col is already init
/** @int : desired columns, like bootstrap (int).
/** @responsive : breakpoint (px)
/*************************************************************/
/***************** MIXINS ******************/
/*****************************************/
/** Mixins import 
******************************************/
/* Rend une image en noir et blanc */
/*************** ANIMATIONS ****************/
/*****************************************
Animations import & mixins
******************************************/
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  70% {
    -moz-transform: scale(0.9);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }
  70% {
    -o-transform: scale(0.9);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  80% {
    -moz-transform: translateY(-10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  80% {
    -o-transform: translateY(-10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }
  80% {
    -moz-transform: translateX(-10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }
  80% {
    -o-transform: translateX(-10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }
  80% {
    -moz-transform: translateX(10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }
  80% {
    -o-transform: translateX(10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }
  80% {
    -moz-transform: translateY(10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }
  80% {
    -o-transform: translateY(10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}
@-moz-keyframes flip {
  0% {
    -moz-transform: perspective(400px) rotateY(0);
    -moz-animation-timing-function: ease-out;
  }
  40% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-animation-timing-function: ease-out;
  }
  50% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
  80% {
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-animation-timing-function: ease-in;
  }
  100% {
    -moz-transform: perspective(400px) scale(1);
    -moz-animation-timing-function: ease-in;
  }
}
@-o-keyframes flip {
  0% {
    -o-transform: perspective(400px) rotateY(0);
    -o-animation-timing-function: ease-out;
  }
  40% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-animation-timing-function: ease-out;
  }
  50% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
  80% {
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-animation-timing-function: ease-in;
  }
  100% {
    -o-transform: perspective(400px) scale(1);
    -o-animation-timing-function: ease-in;
  }
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}
.flip {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInX {
  0% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -moz-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -moz-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInX {
  0% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -o-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -o-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInY {
  0% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -moz-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -moz-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInY {
  0% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -o-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -o-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutX {
  0% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutX {
  0% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: flipOutX;
  -moz-backface-visibility: visible !important;
  -o-animation-name: flipOutX;
  -o-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important;
}
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutY {
  0% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutY {
  0% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipOutY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
/************* ELEMENTS ISOLES *************/
/*!** _elements/_base.less - Fichier destiné aux styles edito ***/
html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  overflow-x: hidden;
}
html.lock-scroll {
  overflow-y: hidden;
}
body {
  margin: 0;
  moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
ul {
  list-style: circle;
}
ul ul,
ol ul {
  list-style: disc inside;
  margin-bottom: 0;
  overflow: hidden;
}
ol {
  list-style: decimal;
}
ol ol,
ul ol {
  list-style: lower-roman inside;
  margin-bottom: 0;
  overflow: hidden;
}
a {
  background-color: transparent;
}
a:active,
a:hover,
a:focus {
  outline: 0;
}
img {
  outline: none;
  outline-color: transparent;
  outline-width: 0;
  box-shadow: none;
  border: none;
}
.general-container {
  padding: 0 12.5%;
  max-width: 1670px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .general-container {
    padding: 0 7.5%;
  }
}
@media (max-width: 480px) {
  .general-container {
    padding: 0 15px;
  }
}
.main div.general-container {
  padding: 0 12.5%;
  max-width: 1670px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .main div.general-container {
    padding: 0 7.5%;
  }
}
@media (max-width: 480px) {
  .main div.general-container {
    padding: 0 15px;
  }
}
.main .tools {
  margin: 30px 0 0;
}
.main .tools-print {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}
.main .tools-print a {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 15px 0 0;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: #2e2d36;
  text-decoration: none;
  display: inline-block;
}
.main .tools-print a i {
  font-size: 22px;
  line-height: 22px;
  color: #2e2d36;
  vertical-align: middle;
  margin: 0 10px 0 0;
}
.main .tools-print a:hover {
  text-decoration: none;
  color: #e87223;
}
.main .tools-print a:hover i {
  color: #e87223;
}
.main .tools div.tools-top {
  margin: 0 0 0 -5px;
}
.main .tools-top {
  display: inline-block;
  vertical-align: : middle;
  width: 50%;
  text-align: right;
}
.main .tools-top a {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-decoration: none;
}
.main .tools-top a:hover {
  text-decoration: none;
}
.main .tools-top a i {
  font-size: 60px;
}
/*!** _elements/_iconfont.less - Fichier destiné aux fonts d'icones ***/
@font-face {
  font-family: 'posocc';
  src: url('/public/fonts/icofont//posocc.eot');
  src: url('/public/fonts/icofont//posocc.eot?#iefix') format('embedded-opentype'), url('/public/fonts/icofont//posocc.woff') format('woff'), url('/public/fonts/icofont//posocc.ttf') format('truetype'), url('/public/fonts/icofont//posocc.svg#posocc') format('svg');
  font-weight: normal;
  font-style: normal;
}
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-subnav-arrow {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-subnav-arrow:before {
  content: "\e909";
}
.icon-pager-right {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pager-right:before {
  content: "\e907";
}
.icon-pager-left {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pager-left:before {
  content: "\e908";
}
.icon-coche {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-coche:before {
  content: "\e900";
}
.icon-deroule {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-deroule:before {
  content: "\e901";
}
.icon-fleche {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-fleche:before {
  content: "\e902";
}
.icon-imprimante {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-imprimante:before {
  content: "\e903";
}
.icon-top {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-top:before {
  content: "\e904";
}
.icon-cadenas {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-cadenas:before {
  content: "\e905";
}
.icon-citation {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'posocc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-citation:before {
  content: "\e906";
}
/*!** _elements/_fonts.less - Fichier destiné à l'import de font et aux mixins associés ***/
@font-face {
  font-family: 'Roboto-Bold';
  src: url('/public/fonts//Roboto-Bold.eot');
  src: url('/public/fonts//Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('/public/fonts//Roboto-Bold.woff') format('woff'), url('/public/fonts//Roboto-Bold.ttf') format('truetype'), url('/public/fonts//Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('/public/fonts//Roboto-Regular.eot');
  src: url('/public/fonts//Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('/public/fonts//Roboto-Regular.woff') format('woff'), url('/public/fonts//Roboto-Regular.ttf') format('truetype'), url('/public/fonts//Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('/public/fonts//Roboto-Medium.eot');
  src: url('/public/fonts//Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('/public/fonts//Roboto-Medium.woff') format('woff'), url('/public/fonts//Roboto-Medium.ttf') format('truetype'), url('/public/fonts//Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('/public/fonts//Roboto-Light.eot');
  src: url('/public/fonts//Roboto-Light.eot?#iefix') format('embedded-opentype'), url('/public/fonts//Roboto-Light.woff') format('woff'), url('/public/fonts//Roboto-Light.ttf') format('truetype'), url('/public/fonts//Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Black';
  src: url('/public/fonts//Roboto-Black.eot');
  src: url('/public/fonts//Roboto-Black.eot?#iefix') format('embedded-opentype'), url('/public/fonts//Roboto-Black.woff') format('woff'), url('/public/fonts//Roboto-Black.ttf') format('truetype'), url('/public/fonts//Roboto-Black.svg#Roboto-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url('/public/fonts//Roboto-Thin.eot');
  src: url('/public/fonts//Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('/public/fonts//Roboto-Thin.woff') format('woff'), url('/public/fonts//Roboto-Thin.ttf') format('truetype'), url('/public/fonts//Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url('/public/fonts//Roboto-Italic.eot');
  src: url('/public/fonts//Roboto-Italic.eot?#iefix') format('embedded-opentype'), url('/public/fonts//Roboto-Italic.woff') format('woff'), url('/public/fonts//Roboto-Italic.ttf') format('truetype'), url('/public/fonts//Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*!** _components/_navigation.less - navigation verticale ***/
.nav {
  margin-bottom: -50px;
  position: relative;
  z-index: 11;
}
@media (max-width: 1030px) {
  .nav {
    margin-bottom: -40px;
  }
}
@media (max-width: 768px) {
  .nav {
    margin-bottom: -50px;
  }
}
@media (max-width: 420px) {
  .nav {
    margin-bottom: -40px;
  }
}
@media (max-width: 380px) {
  .nav {
    margin-bottom: -10px;
  }
}
.nav-container {
  padding: 15px 0% 0 10%;
  position: relative;
}
@media (max-width: 1420px) {
  .nav-container {
    padding: 15px 0% 0 30px;
  }
}
@media (max-width: 1410px) {
  .nav-container {
    padding: 15px 0% 10px 30px;
  }
}
@media (max-width: 420px) {
  .nav-container {
    padding: 15px 0% 0 15px;
  }
}
.nav .logo {
  display: inline-block;
  width: 190px;
  vertical-align: top;
  background-color: #ffffff;
  text-align: center;
  padding: 10px 0px;
  border-radius: 8px;
  border: 1px solid grey;
  position: relative;
}
@media (max-width: 1310px) {
  .nav .logo {
    width: 150px;
  }
}
@media (max-width: 1030px) {
  .nav .logo {
    width: 120px;
  }
}
@media (max-width: 420px) {
  .nav .logo {
    width: 100px;
  }
}
@media (max-width: 380px) {
  .nav .logo {
    width: 70px;
  }
}
.nav .logo img {
  max-width: 100%;
  display: inline-block;
}
.nav-blks {
  display: inline-block;
  width: 85%;
  vertical-align: top;
  margin: 0 0 0 -5px;
  text-align: right;
}
@media (max-width: 992px) {
  .nav-blks {
    width: 83%;
  }
}
@media (max-width: 768px) {
  .nav-blks {
    width: 70%;
    float: right;
    padding-right: 15px;
  }
}
@media (max-width: 320px) {
  .nav-blks {
    width: 72%;
  }
}
.nav-blk {
  display: inline-block;
  margin: 0 0 0 -4px;
  padding: 0 0 0 20px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1370px) {
  .nav-blk {
    padding: 0 0 0 10px;
  }
}
.nav-blk img {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: bottom;
}
@media (max-width: 1220px) {
  .nav-blk img {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 992px) {
  .nav-blk img {
    display: block;
    margin: 0 auto;
  }
}
.nav-blk > span {
  display: inline-block;
  padding: 10px 20px 5px 0;
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #2e2d36;
  text-transform: uppercase;
  border-right: 1px solid #aeb4b7;
  transition: all 0.2s;
}
@media (max-width: 1220px) {
  .nav-blk > span {
    font-size: 14px;
    line-height: 14px;
    padding: 10px 10px 5px 0;
  }
}
@media (max-width: 800px) {
  .nav-blk > span {
    font-size: 13px;
    line-height: 13px;
  }
}
.nav-blk.a-blk.CMS_current span {
  color: #e87223;
}
.nav-blk.a-blk:hover span {
  color: #e87223;
}
@media (max-width: 768px) {
  .nav-blk.sub-blk,
  .nav-blk.a-blk {
    display: none;
  }
}
.nav-blk .nb-news {
  border: 0;
}
.nav-blk ul {
  display: none;
  text-align: left;
  position: absolute;
  top: 100%;
  left: 50%;
  margin: 0 0 0 -120px;
  background-color: #ffffff;
  z-index: 10;
  padding: 20px;
  width: 240px;
  list-style: none;
  box-shadow: 8px 9px 8.28px 0.72px rgba(64, 64, 65, 0.28);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
@media (max-width: 992px) {
  .nav-blk ul {
    width: 200px;
    margin: 0 0 0 -100px;
  }
}
.nav-blk ul li {
  border-bottom: 1px solid #99989c;
  position: relative;
  display: block;
  padding-left: 10px;
  list-style: none;
}
.nav-blk ul li:before {
  content: "• ";
  color: #e87223;
  position: absolute;
  left: 0;
  top: 7px;
}
.nav-blk ul li:last-child {
  border-bottom: none;
}
.nav-blk ul li a {
  color: #2e2d36;
  text-decoration: none;
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  padding: 5px 0;
}
.nav-blk ul li a:hover {
  color: #e87223;
}
.nav-blk ul li a.CMS_current {
  color: #e87223;
}
@media (max-width: 992px) {
  .nav-blk ul li a {
    font-size: 14px;
    line-height: 17px;
  }
}
.nav-blk:hover ul {
  display: block;
}
.nav-blk:hover > span {
  color: #e87223;
}
.nav-blk.espace {
  padding: 15px 40px 0 40px;
  position: relative;
  cursor: inherit;
}
.nav-blk.espace span.login,
.nav-blk.espace span.bjr {
  display: block;
  margin: 0;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #2e2d36;
}
.nav-blk.espace span.bjr {
  margin: 15px 0 0;
}
.nav-blk.espace span.login {
  margin: 0 0 15px;
}
@media (max-width: 1370px) {
  .nav-blk.espace {
    padding: 15px 20px 0 20px;
  }
}
@media (max-width: 1220px) {
  .nav-blk.espace {
    padding: 10px 10px 0;
  }
}
@media (max-width: 768px) {
  .nav-blk.espace {
    padding: 0 10px;
    vertical-align: middle;
  }
}
.nav-blk.espace img {
  width: 20px;
  fill: white;
}
.nav-blk.espace .espace-btn {
  border: 1px solid #e87223;
  background-color: #e87223;
  padding: 10px 20px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.2s;
}
.nav-blk.espace .espace-btn:hover {
  background-color: #ffffff;
}
.nav-blk.espace .espace-btn:hover span,
.nav-blk.espace .espace-btn:hover i {
  color: #e87223;
}
@media (max-width: 320px) {
  .nav-blk.espace .espace-btn {
    padding: 10px;
  }
}
.nav-blk.espace span,
.nav-blk.espace i {
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
}
.nav-blk.espace span {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  text-transform: none;
}
.nav-blk.espace i {
  font-size: 20px;
  margin: 0 5px 0 0;
}
@media (max-width: 992px) {
  .nav-blk.espace i {
    display: none;
  }
}
.nav-blk.espace .epopup {
  position: absolute;
  top: 10px;
  right: 20px;
  display: none;
}
@media (max-width: 1220px) {
  .nav-blk.espace .epopup {
    right: 0;
  }
}
@media (max-width: 992px) {
  .nav-blk.espace .epopup {
    top: 0;
  }
}
.nav-blk.espace .epopup.pop-spec {
  display: block;
}
.nav-blk.espace .epopup-cont {
  padding: 15px 25px 20px 25px;
  width: 240px;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 3px 4px 8.46px 0.54px rgba(64, 64, 65, 0.28);
  border-radius: 20px;
}
.nav-blk.espace .epopup-cont input[type="text"],
.nav-blk.espace .epopup-cont input[type="password"] {
  border-radius: 4px;
  background-color: rgba(232, 114, 35, 0.1);
  height: 28px;
  margin-bottom: 8px;
  border: 0;
  padding: 0 10px;
  width: 100%;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2d36;
}
.nav-blk.espace .epopup-cont input[type="text"]::-webkit-input-placeholder,
.nav-blk.espace .epopup-cont input[type="password"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #2e2d36;
  font-family: 'Roboto-Italic';
  font-weight: 400;
}
.nav-blk.espace .epopup-cont input[type="text"]::-moz-placeholder,
.nav-blk.espace .epopup-cont input[type="password"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #2e2d36;
  font-family: 'Roboto-Italic';
  font-weight: 400;
}
.nav-blk.espace .epopup-cont input[type="text"]:-ms-input-placeholder,
.nav-blk.espace .epopup-cont input[type="password"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #2e2d36;
  font-family: 'Roboto-Italic';
  font-weight: 400;
}
.nav-blk.espace .epopup-cont input[type="text"]:-moz-placeholder,
.nav-blk.espace .epopup-cont input[type="password"]:-moz-placeholder {
  /* Firefox 18- */
  color: #2e2d36;
  font-family: 'Roboto-Italic';
  font-weight: 400;
}
.nav-blk.espace .epopup-cont input[type="submit"] {
  border: 1px solid #e87223;
  background-color: #e87223;
  padding: 8px 20px;
  border-radius: 40px;
  color: #ffffff;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  cursor: pointer;
  transition: all 0.2s;
}
.nav-blk.espace .epopup-cont input[type="submit"]:hover {
  color: #e87223;
  background-color: #ffffff;
}
.nav-blk.espace .epopup-cont .password-link {
  text-align: center;
  display: block;
  margin-top: 10px;
}
.nav-blk.espace .epopup-cont .mdp-forgot,
.nav-blk.espace .epopup-cont .logout {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: #2e2d36;
  text-decoration: none;
}
.nav-blk.espace .epopup-cont .mdp-forgot:hover,
.nav-blk.espace .epopup-cont .logout:hover {
  text-decoration: underline;
}
.nav-blk.espace .epopup-cont .connexion.switchoff {
  display: inline-block;
  margin: 0 0 10px;
  border: 1px solid #e87223;
  background-color: #e87223;
  padding: 8px 20px;
  border-radius: 40px;
  color: #ffffff;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s;
}
.nav-blk.espace .epopup-cont .connexion.switchoff:hover {
  color: #e87223;
  background-color: #ffffff;
}
.nav-blk.espace .epopup-title span {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  text-transform: none;
}
.nav-blk.espace .epopup-title i {
  font-size: 20px;
  margin: 0 5px 0 0;
}
.nav-blk.espace .epopup-title span,
.nav-blk.espace .epopup-title i {
  color: #e87223;
  display: inline-block;
  vertical-align: middle;
}
.mobile-trig {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  padding: 2px;
}
@media (min-width: 769px) {
  .mobile-trig {
    display: none;
  }
}
.mobile-btn {
  width: 38px;
  height: 24px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}
.mobile-btn span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #e87223;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.mobile-btn span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.mobile-btn span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.mobile-btn span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.mobile-btn.open span {
  background-color: #ffffff;
}
.mobile-btn.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}
.mobile-btn.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.mobile-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 24px;
  left: 8px;
}
.mobile-btn:active,
.mobile-btn:focus {
  outline: none;
}
.mob-nav {
  transform: translate(0px, -250%);
  -webkit-transform: translate(0px, -250%);
  -moz-transform: translate(0px, -250%);
  -o-transform: translate(0px, -250%);
  -ms-transform: translate(0px, -250%);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #484554;
  transition: all 0.3s ease;
  overflow-y: scroll;
  z-index: 10;
}
@media (max-width: 768px) {
  .mob-nav {
    display: block;
  }
}
.mob-nav.active {
  transform: translate(0px, 0);
  -webkit-transform: translate(0px, 0%);
  -moz-transform: translate(0px, 0%);
  -o-transform: translate(0px, 0%);
  -ms-transform: translate(0px, 0%);
}
.mob-nav-container {
  padding: 0 20px;
  text-align: center;
  overflow-y: scroll;
}
.mnav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mnav nav {
  padding: 50px 15px;
}
.mnav-blk {
  padding: 20px 15px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
.mnav-blk img {
  display: inline-block;
  max-width: 50px;
  vertical-align: middle;
  margin-right: 15px;
  display: none;
}
.mnav-blk span {
  display: block;
  vertical-align: middle;
  color: #ffffff;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
}
.mnav-blk ul {
  display: inline-block;
  text-align: left;
  padding: 0;
  margin: 10px 0 0;
  list-style: none;
}
.mnav-blk li {
  position: relative;
  display: block;
  padding-left: 10px;
}
.mnav-blk a {
  color: #ffffff;
  text-decoration: none;
  display: block;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  padding: 5px 0;
}
.mnav-blk.mnav-news {
  text-decoration: none;
  display: block;
}
/*!** _elements/_edito.less - Fichier destiné aux styles edito ***/
/************** TEXTES ******/
html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}
.main p {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2e2d36;
  margin: 0 0 15px;
}
@media (max-width: 992px) {
  .main p {
    font-size: 15px;
    line-height: 21px;
  }
}
@media (max-width: 992px) {
  .main p {
    font-size: 14px;
    line-height: 20px;
  }
}
.main div {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2e2d36;
  margin: 0 0 15px;
}
@media (max-width: 992px) {
  .main div {
    font-size: 15px;
    line-height: 21px;
  }
}
@media (max-width: 992px) {
  .main div {
    font-size: 14px;
    line-height: 20px;
  }
}
.main a {
  color: #e87223;
  text-decoration: none;
}
.main a:hover {
  text-decoration: underline;
}
.main strong {
  font-family: 'Roboto-Medium';
  font-weight: 300;
}
.main li {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2e2d36;
}
@media (max-width: 992px) {
  .main li {
    font-size: 15px;
    line-height: 21px;
  }
}
@media (max-width: 992px) {
  .main li {
    font-size: 14px;
    line-height: 20px;
  }
}
.main ul {
  list-style: none;
  padding: 0 0 0 10px;
  margin: 0 0 25px;
}
.main ul li {
  padding-left: 10px;
  position: relative;
  margin: 0 0 10px;
}
.main ul li:before {
  content: "• ";
  position: absolute;
  left: 0;
  top: 3px;
  color: #e87223;
}
.main ol {
  counter-reset: item;
  padding: 0 0 0 10px;
}
.main ol li {
  display: block;
}
.main ol li:before {
  content: counter(item) ". ";
  counter-increment: item;
  color: #e87223;
}
.fancybox .img-responsive {
  display: block;
  max-width: 100%;
}
/************** TABLE ******/
table {
  border: 1px solid #484554;
  margin: 0 auto;
  text-align: center;
  border-collapse: collapse;
}
tr {
  border: 0;
}
td,
th {
  border: 1px solid #484554;
  padding: 10px;
}
thead {
  color: #e87223;
}
/************** UL LI ******
ul {
	li {

	}
}
*/
.main .page-infos {
  position: relative;
  padding: 25px 90px 0 0;
}
.main .page-infos-pictos {
  position: absolute;
  right: 0;
  top: 25px;
}
.main .page-infos-pictos img {
  width: 90px;
  height: 90px;
}
.main .highlights {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #03869a;
  background-color: rgba(70, 185, 193, 0.05);
  padding: 15px 20px;
  margin: 0 0 30px;
}
.main .highlights p,
.main .highlights div {
  margin: 0;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #03869a;
}
.highlights {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #03869a;
  background-color: rgba(70, 185, 193, 0.05);
  padding: 15px 20px;
  margin: 0 0 40px;
}
.highlights p {
  margin: 0;
}
.highlights div {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #03869a;
  margin: 0;
}
a.media-pdf,
a.pmedia-file {
  display: block;
  position: relative;
  padding: 8px 0 10px 45px;
  margin: 0 0 10px;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #2e2d36;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
a.media-pdf:hover,
a.pmedia-file:hover {
  color: #e87223;
  text-decoration: none;
}
a.media-pdf img,
a.pmedia-file img {
  position: absolute;
  left: 0;
  top: 5px;
}
hr {
  border-color: rgba(0, 0, 0, 0.25);
}
/*!** _elements/_breadcrumb.less - Breadcrumb ***/
.breadcrumb {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2d36;
  margin: 0 0 40px;
}
.breadcrumb a,
.breadcrumb span {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2d36;
  text-decoration: none;
  display: inline-block;
  margin: 0 4px;
}
.breadcrumb a:hover {
  text-decoration: underline;
}
/*!** _elements/_buttons.less - Fichier destiné aux styles des boutons ***/
a.btn {
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  padding: 12px 35px;
  border-radius: 50px;
  transition: all 0.2s;
}
@media (max-width: 992px) {
  a.btn {
    font-size: 15px;
    line-height: 21px;
    padding: 11px 30px;
  }
}
@media (max-width: 992px) {
  a.btn {
    font-size: 14px;
    line-height: 20px;
    padding: 9px 30px;
  }
}
a.btn:hover {
  background-color: transparent;
  text-decoration: none;
}
a.btn:focus,
a.btn:active {
  outline: none;
}
a.btnlb {
  border: 1px solid #46b9c1;
  background-color: #46b9c1;
}
a.btnlb:hover {
  color: #46b9c1;
}
a.btnorg {
  border: 1px solid #e87223;
  background-color: #e87223;
}
a.btnorg:hover {
  color: #e87223;
}
a.btndkg {
  border: 1px solid #484554;
  background-color: #484554;
}
a.btndkg:hover {
  color: #484554;
}
a.btntrs {
  border: 1px solid #484554;
  background-color: #ffffff;
  color: #484554;
}
a.btntrs:hover {
  color: #ffffff;
  background-color: #484554;
}
a.btnw {
  border: 1px solid #ffffff;
  color: #e87223;
  background-color: #ffffff;
}
a.btnw:hover {
  color: #ffffff;
}
div.search-form-label {
  margin: 0 0 5px;
}
.cms_forms .cms_forms_error_msg {
  padding: 15px 20px;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #2e2d36;
  background-color: rgba(232, 114, 35, 0.15);
  margin: 20px 0;
  text-align: center;
  border: 2px solid #e87223;
}
.cms_forms .cms_forms_error_msg ul {
  margin: 10px 0 0;
  padding: 0;
}
.cms_forms .cms_forms_error_msg ul li {
  color: #dc600d;
  display: inline-block;
  margin: 0 5px;
}
.cms_forms .cms_forms_msg {
  background-color: #46b9c1;
  color: #ffffff;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  padding: 20px 15px;
  text-align: center;
}
.cms_forms table {
  border: 0;
  padding: 28px 10%;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  border-collapse: inherit;
}
@media (max-width: 480px) {
  .cms_forms table {
    padding: 20px 15px;
  }
}
.cms_forms td,
.cms_forms th {
  border: 0;
  padding: inherit;
}
.cms_forms td {
  text-align: left !important;
}
@media (max-width: 768px) {
  .cms_forms td {
    display: block;
  }
}
.cms_forms td .field.require {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
}
.cms_forms tr {
  height: 50px;
}
@media (max-width: 768px) {
  .cms_forms tr {
    height: inherit;
    display: block;
  }
}
.cms_forms thead {
  color: #2e2d36;
}
.cms_forms label {
  display: block;
  text-align: left;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  color: #2e2d36;
  margin: 15px 0 5px;
}
.cms_forms span.required {
  color: red;
}
.cms_forms input[type="text"],
.cms_forms input[type="email"] {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 15px;
  color: #2e2d36;
  background-color: #f6f6f6;
  height: 35px;
  border: 0;
  width: 300px;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .cms_forms input[type="text"],
  .cms_forms input[type="email"] {
    width: 100%;
  }
}
.cms_forms textarea {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  color: #2e2d36;
  background-color: #f6f6f6;
  border: 0;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .cms_forms textarea {
    width: 100%;
  }
}
.cms_forms input[type="submit"] {
  display: inline-block;
  position: relative;
  background-color: #e87223;
  color: #ffffff;
  padding: 10px 40px;
  margin: 10px auto 0;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  border-radius: 50px;
  text-decoration: none;
  border: 0;
  box-shadow: 0 0 0;
  transition: all 0.2s ease;
  border: 1px solid #e87223;
  cursor: pointer;
}
.cms_forms input[type="submit"]:hover,
.cms_forms input[type="submit"] a:active,
.cms_forms input[type="submit"]:focus {
  outline: none;
  background-color: #ffffff;
  text-decoration: none;
  color: #e87223;
}
@media (max-width: 768px) {
  .cms_forms input[type="submit"] {
    display: block;
    margin: 0 auto;
  }
}
.cms_forms .g-recaptcha {
  display: inline-block;
  margin: -15px 0 0;
  text-align: left;
}
.cms_forms .g-recaptcha > div {
  margin: 0 auto;
}
.epopup .cms_forms_error_msg ul {
  position: relative;
  top: inherit;
  left: inherit;
  background-color: transparent;
  display: block;
  box-shadow: inherit;
  width: 100%;
}
.epopup .cms_forms_error_msg ul li {
  border: 0;
}
.epopup .cms_forms_error_msg ul li:before {
  top: 2px;
}
#cms_forms {
  margin: 30px 0;
}
#cms_forms table {
  border: 0;
  padding: 28px 10%;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  margin: 0;
}
@media (max-width: 480px) {
  #cms_forms table {
    padding: 20px 15px;
  }
}
#cms_forms td,
#cms_forms th {
  border: 0;
}
#cms_forms td {
  text-align: left !important;
  display: block;
  margin: 0 0 10px;
}
@media (max-width: 768px) {
  #cms_forms td {
    display: block;
  }
}
#cms_forms tr {
  height: 50px;
}
@media (max-width: 768px) {
  #cms_forms tr {
    height: inherit;
    display: block;
  }
}
#cms_forms label {
  display: block;
  text-align: left;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  color: #2e2d36;
  margin: 15px 0 5px;
}
#cms_forms input[type="text"] {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 15px;
  color: #2e2d36;
  background-color: #f6f6f6;
  height: 35px;
  border: 0;
  width: 300px;
  border-radius: 8px;
  padding: 0 10px;
}
@media (max-width: 768px) {
  #cms_forms input[type="text"] {
    width: 100%;
  }
}
#cms_forms input[type="submit"] {
  display: inline-block;
  position: relative;
  background-color: #e87223;
  color: #ffffff;
  padding: 10px 40px;
  margin: 0 auto;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  border-radius: 50px;
  text-decoration: none;
  border: 0;
  box-shadow: 0 0 0;
  transition: all 0.2s ease;
  border: 1px solid #e87223;
  cursor: pointer;
}
#cms_forms input[type="submit"]:hover,
#cms_forms input[type="submit"] a:active,
#cms_forms input[type="submit"]:focus {
  outline: none;
  background-color: #ffffff;
  text-decoration: none;
  color: #e87223;
}
@media (max-width: 768px) {
  #cms_forms input[type="submit"] {
    display: block;
    margin: 0 auto;
  }
}
#subscribe-formular .form-title,
#unsubscribe-formular .form-title {
  position: relative;
  display: inline-block;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #2e2d36;
  margin: 0 0 015px;
  padding: 0 0 8px;
}
#subscribe-formular .form-title:after,
#unsubscribe-formular .form-title:after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #e87223;
  position: absolute;
  left: 0;
  bottom: 0;
}
#subscribe-formular ul.subtheme,
#unsubscribe-formular ul.subtheme {
  padding: 0;
  margin: 0;
  list-style: none;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
@media (max-width: 1200px) {
  #subscribe-formular ul.subtheme,
  #unsubscribe-formular ul.subtheme {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 600px) {
  #subscribe-formular ul.subtheme,
  #unsubscribe-formular ul.subtheme {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
#subscribe-formular ul.subtheme li:before,
#unsubscribe-formular ul.subtheme li:before {
  display: none;
}
#subscribe-formular label,
#unsubscribe-formular label {
  display: inline-block;
  text-align: left;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  color: #2e2d36;
}
#subscribe-formular input[type="checkbox"],
#unsubscribe-formular input[type="checkbox"] {
  display: inline-block;
}
#subscribe-formular input[type="text"],
#unsubscribe-formular input[type="text"] {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 15px;
  color: #2e2d36;
  background-color: #f6f6f6;
  height: 35px;
  border: 0;
  width: 300px;
  border-radius: 8px;
  margin: 0 0 0 10px;
}
@media (max-width: 768px) {
  #subscribe-formular input[type="text"],
  #unsubscribe-formular input[type="text"] {
    width: 100%;
  }
}
#subscribe-formular textarea,
#unsubscribe-formular textarea {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  color: #2e2d36;
  background-color: #f6f6f6;
  border: 0;
}
@media (max-width: 768px) {
  #subscribe-formular textarea,
  #unsubscribe-formular textarea {
    width: 100%;
  }
}
#subscribe-formular .button,
#unsubscribe-formular .button {
  display: block;
  position: relative;
  background-color: #e87223;
  color: #ffffff;
  padding: 10px 40px;
  margin: 0 auto;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  border-radius: 50px;
  text-decoration: none;
  border: 0;
  box-shadow: 0 0 0;
  transition: all 0.2s ease;
  border: 1px solid #e87223;
  cursor: pointer;
}
#subscribe-formular .button.first-submit,
#unsubscribe-formular .button.first-submit {
  display: inline-block;
  margin: 0 0 0 10px;
}
#subscribe-formular .button:hover,
#unsubscribe-formular .button:hover,
#subscribe-formular .button a:active,
#unsubscribe-formular .button a:active,
#subscribe-formular .button:focus,
#unsubscribe-formular .button:focus {
  outline: none;
  background-color: #ffffff;
  text-decoration: none;
  color: #e87223;
}
@media (max-width: 768px) {
  #subscribe-formular .button,
  #unsubscribe-formular .button {
    display: block;
    margin: 0 auto;
  }
}
#subscribe-formular .check,
#unsubscribe-formular .check,
#subscribe-formular .uncheck,
#unsubscribe-formular .uncheck {
  margin: 0 10px 30px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: 140px;
  border: 1px solid #2e2d36;
  background-color: #2e2d36;
  text-align: center;
  padding: 5px 10px;
  transition: all 0.2s;
}
#subscribe-formular .check:hover,
#unsubscribe-formular .check:hover,
#subscribe-formular .uncheck:hover,
#unsubscribe-formular .uncheck:hover {
  text-decoration: none;
  color: #2e2d36;
  background-color: #ffffff;
}
#subscribe-formular .check:focus,
#unsubscribe-formular .check:focus,
#subscribe-formular .uncheck:focus,
#unsubscribe-formular .uncheck:focus {
  outline: none;
}
p.update-message.error,
p.suscribe-status.error {
  margin: 20px 0;
  background-color: rgba(232, 114, 35, 0.15);
  border: 2px solid #e87223;
  padding: 15px 20px;
  text-align: center;
  display: block;
  color: #e87223;
}
p.update-message.error span,
p.suscribe-status.error span {
  font-size: 24px;
  line-height: 24px;
}
p.update-message.error a,
p.suscribe-status.error a {
  text-decoration: underline;
}
p.suscribe-status.abonne {
  background-color: #46b9c1;
  color: #ffffff;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  padding: 20px 15px;
  text-align: center;
}
/*!** _elements/_heading.less - Fichier destiné aux styles des titres ***/
h1 {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  color: #2e2d36;
  position: relative;
  padding: 0 90px 10px 0;
}
h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background-color: #46b9c1;
}
@media (max-width: 1200px) {
  h1 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  h1 {
    font-size: 26px;
    line-height: 30px;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 23px;
    line-height: 26px;
  }
}
body.contact .main h1 {
  margin: 0 0 40px;
}
h2 {
  position: relative;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #2e2d36;
  margin: 40px 0 20px;
  padding: 0 0 8px;
}
h2:after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #e87223;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 1200px) {
  h2 {
    font-size: 23px;
    line-height: 25px;
  }
}
@media (max-width: 992px) {
  h2 {
    font-size: 22px;
    line-height: 24px;
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 20px;
    line-height: 22px;
  }
}
body.contact .main h2 {
  margin: 0 0 10px;
}
h3 {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #dc600d;
}
@media (max-width: 1200px) {
  h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  h3 {
    font-size: 19px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  h3 {
    font-size: 18px;
    line-height: 20px;
  }
}
h4 {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #2e2d36;
  padding: 5px 10px;
  background-color: #ecf8f9;
  display: inline-block;
}
@media (max-width: 768px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}
/*!** _elements/_image.less - Fichier destiné aux styles des images ***/
/*!** _elements/_gotop.less - Go to top ***/
.newslist {
  margin: 0 0 45px;
  text-align: center;
}
.newslist ul.newslist-row {
  padding: 0;
  font-size: 0;
}
.newslist ul.newslist-row:after {
  display: inline-block;
  width: 100%;
}
@media (min-width: 768px) {
  .newslist ul.newslist-row {
    margin: 0 -10px;
  }
}
.newslist-row {
  list-style: none;
}
.newslist li.newslist-col {
  font-size: initial;
  position: relative;
  word-break: break-word;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 10px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .newslist li.newslist-col {
    width: 50%;
    padding: 10px;
  }
}
@media (max-width: 480px) {
  .newslist li.newslist-col {
    width: 100%;
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .newslist li.newslist-col.nlagenda {
    width: 100%;
    padding: 0px;
  }
}
.newslist li.newslist-col:before {
  display: none;
}
.newslist-a {
  display: block;
  text-align: center;
  text-decoration: none;
}
.newslist-a img {
  max-width: 100%;
  display: inline-block;
  margin: 0 auto 10px;
  height: auto;
}
.newslist-a:hover {
  text-decoration: none !important;
}
.newslist-a:hover h2,
.newslist-a:hover h3 {
  color: #03869a;
}
.newslist-a:hover .newslist-title:after {
  width: 100%;
}
.newslist h2 {
  margin-top: 20px;
  text-align: left;
}
.newslist h3 {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #2e2d36;
  text-align: left;
  position: relative;
  margin: 0 0 8px;
  padding: 0 20px 10px 0;
  transition: all 0.2s;
}
@media (max-width: 992px) {
  .newslist h3 {
    font-size: 17px;
    line-height: 20px;
  }
}
@media (max-width: 768px) {
  .newslist h3 {
    font-size: 16px;
    line-height: 18px;
  }
}
.newslist-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 30px;
  background-color: #46b9c1;
  transition: all 0.2s;
}
.newslist div.newslist-infos {
  margin: 0 0 8px;
}
.newslist-infos {
  text-align: left;
}
.newslist-infos span {
  display: inline-block;
}
.newslist-cat {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #03869a;
  border-right: 1px solid #2e2d36;
  padding: 0 5px 0 0;
  margin: 0 2px 0 0;
}
.newslist-date {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #2e2d36;
}
.newslist-intro {
  margin: 0 0 8px;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2e2d36;
  text-align: left;
}
@media (max-width: 992px) {
  .newslist-intro {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 768px) {
  .newslist-intro {
    font-size: 14px;
    line-height: 18px;
  }
}
.newslist-more {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  text-decoration: underline;
  color: #2e2d36;
  text-align: left;
  display: block;
}
.nlagenda .newslist-a {
  background-color: #fff1e4;
}
.nlagenda .newslist-cont {
  padding: 0 15px 30px;
}
.nlagenda .newslist-cat {
  border-right: 0;
  background-color: #46b9c1;
  border-radius: 2px;
  color: #ffffff;
  display: inline-block;
  padding: 4px 6px;
}
.nlagenda .newslist-date {
  font-family: 'Roboto-Bold';
  font-weight: 400;
}
.eventlist {
  margin: 40px 0;
  padding: 25px 0 0;
  background-color: #fff1e4;
}
.eventlist ul.eventlist-row {
  padding: 0;
  font-size: 0;
}
.eventlist ul.eventlist-row:after {
  display: inline-block;
  width: 100%;
}
@media (min-width: 768px) {
  .eventlist ul.eventlist-row {
    margin: 0 -10px;
  }
}
.eventlist-row {
  list-style: none;
  text-align: center;
}
.eventlist li.eventlist-col {
  font-size: initial;
  position: relative;
  word-break: break-word;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 10px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .eventlist li.eventlist-col {
    width: 50%;
    padding: 10px;
  }
}
@media (max-width: 480px) {
  .eventlist li.eventlist-col {
    width: 100%;
    padding: 0px;
  }
}
.eventlist li.eventlist-col:before {
  display: none;
}
.eventlist-a {
  display: block;
  text-align: center;
  text-decoration: none;
}
.eventlist-a img {
  max-width: 100%;
  display: inline-block;
  margin: 0 auto 10px;
  height: auto;
}
.eventlist-a:hover {
  text-decoration: none !important;
}
.eventlist-a:hover h2,
.eventlist-a:hover h3 {
  color: #03869a;
  text-decoration: none;
}
.eventlist-a:hover .newslist-title:after {
  width: 100%;
}
.eventlist-cont {
  padding: 10px 15px 30px;
}
.eventlist h3 {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #2e2d36;
  text-align: left;
  position: relative;
  margin: 0 0 8px;
  padding: 0;
  transition: all 0.2s;
}
@media (max-width: 992px) {
  .eventlist h3 {
    font-size: 17px;
    line-height: 20px;
  }
}
@media (max-width: 768px) {
  .eventlist h3 {
    font-size: 16px;
    line-height: 18px;
  }
}
.eventlist div.eventlist-infos {
  margin: 0 0 8px;
}
.eventlist-infos {
  text-align: left;
  margin: 0 0 8px;
}
.eventlist-infos span {
  display: inline-block;
}
.eventlist-cat {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  background-color: #46b9c1;
  color: #ffffff;
  padding: 4px 6px;
  border-radius: 2px;
  display: inline-block;
}
.eventlist-date {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #2e2d36;
}
.eventlist-intro {
  margin: 0 0 8px;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2e2d36;
  text-align: left;
}
.eventlist-more {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  text-decoration: underline;
  color: #2e2d36;
  text-align: left;
  display: block;
}
body.agenda .newslist {
  margin: 0;
}
body.agenda .newslist-col {
  margin-bottom: 60px;
}
@media (max-width: 480) {
  body.agenda .newslist-col {
    margin-bottom: 10px;
  }
}
div.pager {
  margin: 35px 0 0;
}
.pager {
  text-align: center;
}
.pager a,
.pager span {
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
}
.pager a {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #2e2d36;
  margin: 0 15px;
}
@media (max-width: 992px) {
  .pager a {
    font-size: 17px;
    line-height: 17px;
  }
}
@media (max-width: 768px) {
  .pager a {
    font-size: 16px;
    line-height: 16px;
  }
}
.pager a:hover {
  text-decoration: none;
  color: #e87223;
}
.pager-span {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #e87223;
  margin: 0 15px;
}
@media (max-width: 992px) {
  .pager-span {
    font-size: 17px;
    line-height: 17px;
  }
}
@media (max-width: 768px) {
  .pager-span {
    font-size: 16px;
    line-height: 16px;
  }
}
.pager-prev {
  color: #2e2d36;
  font-size: 23px;
  line-height: 23px;
  margin: 0 50px 0 0;
}
.pager-prev:hover {
  color: #e87223;
}
.pager-next {
  color: #2e2d36;
  font-size: 23px;
  line-height: 23px;
  margin: 0 0 0 50px;
}
.pager-next:hover {
  color: #e87223;
}
.ress ul.ress-row {
  padding: 0;
  margin: 0;
  font-size: 0;
}
.ress ul.ress-row:after {
  display: inline-block;
  width: 100%;
}
@media (min-width: 768px) {
  .ress ul.ress-row {
    margin: 0 -15px;
  }
}
.ress-row {
  list-style: none;
}
.ress li.ress-col {
  font-size: initial;
  position: relative;
  word-break: break-word;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 15px;
  margin: 0;
}
@media (max-width: 768px) {
  .ress li.ress-col {
    width: 75%;
    padding: 15px;
  }
}
@media (max-width: 480px) {
  .ress li.ress-col {
    width: 100%;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .ress li.ress-col {
    margin: 0 0 10px;
  }
}
.ress li.ress-col:before {
  display: none;
}
.ress a.ress-cont:hover {
  text-decoration: none;
}
.ress-cont {
  display: block;
  padding: 30px 20px 50px;
  background-color: #ffffff;
  height: 100%;
  position: relative;
  text-decoration: none;
  text-align: left;
}
.ress-cont h3 {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #2e2d36;
  margin: 0 0 10px;
  padding: 0 0 10px;
  position: relative;
  transition: all 0.2s;
}
.ress-cont h3:after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #e87223;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.2s;
}
.ress-cont:hover h3 {
  color: #e87223;
}
.ress-cont:hover h3:after {
  width: 100%;
}
.ress-date {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2e2d36;
  margin: 0 0 10px;
  display: block;
}
@media (max-width: 768px) {
  .ress-date {
    font-size: 13px;
    line-height: 15px;
  }
}
.ress-themes {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-right: 50px;
}
@media (max-width: 768px) {
  .ress-themes {
    font-size: 13px;
    line-height: 15px;
  }
}
.ress div.ress-themes {
  color: #03869a;
}
.ress-img {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 50px;
}
.ress-img img {
  max-width: 100%;
  height: auto;
  display: block;
}
.ress .btn {
  margin-top: 40px;
}
form.form-select {
  display: block;
  padding: 10px;
  background-color: rgba(63, 77, 83, 0.05);
  margin: 0 0 5px;
}
@media (max-width: 768px) {
  form.form-select {
    text-align: center;
  }
}
form.form-select i.icon-deroule {
  position: absolute;
  right: 10px;
  font-size: 12px;
  line-height: 40px;
  color: #2e2d36;
}
form.form-select input[type="submit"] {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #484554;
  border: 2px solid #484554;
  color: #ffffff;
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 15px;
  padding: 0;
  text-align: center;
}
form.form-select input[type="submit"]:hover {
  background-color: transparent;
  color: #484554;
}
form.form-select input[type="submit"]:active,
form.form-select input[type="submit"]:focus {
  outline: none;
}
@media (max-width: 480px) {
  form.form-select input[type="submit"] {
    display: block;
    margin: 10px auto 0;
    width: inherit;
    height: inherit;
    padding: 5px 20px;
  }
}
form.form-select input[type="checkbox"] {
  cursor: pointer;
}
form.form-select label {
  font-family: 'Roboto-Medium';
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #2e2d36;
  cursor: pointer;
}
form.form-select option {
  text-transform: none;
  letter-spacing: 0;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #2e2d36;
}
div.fks {
  margin: 0 5px;
}
.fks {
  display: inline-block;
  vertical-align: middle;
  background-color: #ffffff;
  position: relative;
}
.fks.fks-themes {
  width: 30%;
}
@media (max-width: 992px) {
  .fks.fks-themes {
    width: 45%;
    margin: 0 5px 10px;
  }
}
@media (max-width: 480px) {
  .fks.fks-themes {
    width: 75%;
  }
}
@media (max-width: 360px) {
  .fks.fks-themes {
    width: 100%;
  }
}
.fks.fks-territoires {
  width: 27%;
}
@media (max-width: 992px) {
  .fks.fks-territoires {
    width: 45%;
    margin: 0 5px 10px;
  }
}
@media (max-width: 480px) {
  .fks.fks-territoires {
    width: 75%;
  }
}
@media (max-width: 360px) {
  .fks.fks-territoires {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .fks {
    text-align: left;
  }
}
.fks-title {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #2e2d36;
  border: 1px solid #2e2d36;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0 10px;
  display: block;
  cursor: pointer;
  position: relative;
}
@media (max-width: 768px) {
  .fks-title {
    font-size: 12px;
  }
}
.fks-block {
  display: none;
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  z-index: 10;
  border: 1px solid #2e2d36;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0;
}
.fks-block.active {
  display: block;
}
.fks ul {
  max-height: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}
@media (max-width: 480px) {
  .fks ul {
    max-height: 150px;
  }
}
.fks ul li {
  margin: 0 0 6px;
  padding: 0;
}
.fks ul li:before {
  display: none;
}
div.rsel {
  display: inline-block;
  vertical-align: middle;
  width: 26%;
  position: relative;
  margin: 0 5px;
  background-color: #ffffff;
}
@media (max-width: 992px) {
  div.rsel {
    width: 45%;
  }
}
@media (max-width: 480px) {
  div.rsel {
    width: 75%;
  }
}
@media (max-width: 360px) {
  div.rsel {
    width: 100%;
  }
}
div.rsel select {
  padding: 0 5px;
  height: 42px;
  width: 100%;
  border: 1px solid #2e2d36;
  background-color: transparent;
  position: relative;
  border-radius: 4px;
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  cursor: pointer;
}
div.rsel select::-ms-expand {
  display: none;
}
div.rsel select:focus,
div.rsel select:active {
  outline: none;
}
@media (max-width: 768px) {
  div.rsel select {
    font-size: 12px;
  }
}
/*************** COMPOSANTS ****************/
.header-band {
  background: #e87223 url(/public/img/bandeaux/bandeau_header_home.jpg) no-repeat left center;
  text-align: center;
  height: 400px;
  background-size: 100%;
  padding: 65px 0 0;
}
@media (max-width: 1030px) {
  .header-band {
    padding: 55px 15px 0;
    background-size: cover;
    height: 350px;
  }
}
@media (max-width: 380px) {
  .header-band {
    padding: 30px 15px 0;
  }
}
.header h1 {
  font-family: 'Roboto-Thin';
  font-weight: 400;
  font-size: 60px;
  line-height: 64px;
  color: #ffffff;
  margin: 0 0 30px;
  padding: 0;
}
.header h1:after {
  display: none;
}
.header h1 span.subtitle {
  display: block;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
}
@media (max-width: 992px) {
  .header h1 {
    font-size: 40px;
    line-height: 44px;
  }
  .header h1 span.subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 480px) {
  .header h1 {
    font-size: 34px;
    line-height: 38px;
  }
  .header h1 span.subtitle {
    font-size: 26px;
    line-height: 28px;
  }
}
.header p {
  font-family: 'Roboto-Light';
  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
@media (max-width: 480px) {
  .header p {
    font-size: 18px;
    line-height: 22px;
  }
}
.header-search {
  max-width: 470px;
  background-color: #ffffff;
  margin: 0 auto;
  padding: 1px;
  border-radius: 50px;
}
.header-search .search-form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}
.header-search .search-form input[type="text"] {
  height: 50px;
  display: inline-block;
  border: 0;
  width: 70%;
  vertical-align: middle;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 0 20px;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #2e2d36;
}
.header-search .search-form input[type="text"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #2e2d36;
  font-family: 'Roboto-Regular';
  font-weight: 400;
}
.header-search .search-form input[type="text"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #2e2d36;
  font-family: 'Roboto-Regular';
  font-weight: 400;
}
.header-search .search-form input[type="text"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #2e2d36;
  font-family: 'Roboto-Regular';
  font-weight: 400;
}
.header-search .search-form input[type="text"]:-moz-placeholder {
  /* Firefox 18- */
  color: #2e2d36;
  font-family: 'Roboto-Regular';
  font-weight: 400;
}
.header-search .search-form input[type="text"]:focus {
  outline: none;
}
.header-search .search-form input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.header-search .search-form input:focus::-webkit-input-placeholder {
  color: transparent;
}
.header-search .search-form input:focus:-moz-placeholder {
  color: transparent;
}
.header-search .search-form input:focus::-moz-placeholder {
  color: transparent;
}
.header-search .search-form input:focus:-ms-input-placeholder {
  color: transparent;
}
.header-search .search-form input[type="submit"] {
  height: 50px;
  border: 0;
  display: inline-block;
  background-color: #484554;
  width: 30%;
  vertical-align: middle;
  margin: 0 0 0 -5px;
  color: #ffffff;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
}
.header-search .search-form input[type="submit"]:hover {
  background-color: #e87223;
}
.int-hb {
  background: #e87223 url(/public/img/bandeaux/bandeau_header_mini.jpg) no-repeat left top;
  text-align: center;
  height: 260px;
  background-size: 100%;
  padding: 65px 15px 15px;
}
@media (max-width: 1030px) {
  .int-hb {
    padding: 55px 15px 0;
    background-size: cover;
  }
}
@media (max-width: 380px) {
  .int-hb {
    padding: 30px 15px 0;
  }
}
.int-hb-title {
  display: block;
  font-family: 'Roboto-Thin';
  font-weight: 400;
  font-size: 35px;
  line-height: 40px;
  color: #ffffff;
  margin: 0 0 8px;
}
@media (max-width: 992px) {
  .int-hb-title {
    font-size: 32px;
    line-height: 35px;
  }
}
@media (max-width: 480px) {
  .int-hb-title {
    font-size: 28px;
    line-height: 30px;
  }
}
.int-hb p {
  margin: 0 0 30px;
  font-family: 'Roboto-Light';
  font-weight: 200;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
@media (max-width: 480px) {
  .int-hb p {
    font-size: 14px;
    line-height: 16px;
  }
}
.int-hb .header-search {
  max-width: 370px;
}
.footer {
  margin: 50px 0;
}
.footer-cont {
  border-top: 1px solid rgba(63, 77, 83, 0.15);
  border-bottom: 1px solid rgba(63, 77, 83, 0.15);
  padding: 20px 15px;
  text-align: center;
}
.footer-title {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #2e2d36;
  margin: 0 0 10px;
}
.footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer li {
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  border-left: 1px solid #2e2d36;
}
.footer li:first-child {
  border-left: 0;
}
@media (max-width: 480px) {
  .footer li {
    display: block;
    border: 0;
  }
}
.footer a {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2d36;
  text-decoration: none;
  display: block;
}
.footer a:hover {
  text-decoration: underline;
}
.footer a.footer-lk-contact {
  font-family: 'Roboto-Bold';
  font-weight: 400;
}
@media (max-width: 480px) {
  .footer a {
    padding: 5px 0;
  }
}
.nl-picto {
  background-color: #ffffff;
  border-radius: 100px;
  height: 120px;
  margin: 0 auto -30px;
  padding: 35px 10px 0;
  position: relative;
  text-align: center;
  width: 120px;
}
.nl-picto img {
  max-width: 90px;
}
.nl-block {
  text-align: center;
  padding: 50px 15px 30px;
  background-image: url(/public/img/graphiques_footer.png);
  background-color: #e87223;
  background-repeat: no-repeat;
  background-position: left 8% bottom -10px;
  background-size: 150px;
}
.nl-block p {
  color: #ffffff;
  margin: 0;
}
.nl-block p.nl-title {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  margin: 0 0 10px;
}
@media (max-width: 480px) {
  .nl-block p.nl-title {
    font-size: 22px;
    line-height: 25px;
  }
}
.nl-block p.nl-subtitle {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  margin: 0 0 20px;
}
@media (max-width: 480px) {
  .nl-block p.nl-subtitle {
    font-size: 16px;
    line-height: 18px;
  }
}
/************* FANCYBOX **************/
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.fancybox-opened {
  z-index: 8030;
}
.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('/public/img/fancybox/fancybox_sprite.png');
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('/public/img/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}
.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url('/public/img/fancybox/blank.gif');
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}
.fancybox-prev {
  left: 0;
}
.fancybox-next {
  right: 0;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}
.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}
.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}
.fancybox-nav:hover span {
  visibility: visible;
}
.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}
/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url('/public/img/fancybox/fancybox_overlay.png');
}
.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}
/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url('/public/img/fancybox/fancybox_sprite@2x.png');
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url('/public/img/fancybox/fancybox_loading@2x.gif');
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}
/*!** _home/Fichier destiné aux styles des titres ***/
/*** _home/_heading.less - Titres pages home ***/
.homenews {
  text-align: center;
}
.home-titles {
  text-align: center;
}
.home-titles h2 {
  margin: -20px 0 0 0;
  position: relative;
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 2px;
  word-spacing: 2px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .home-titles h2 {
    font-size: 20px;
    line-height: 20px;
  }
}
@media (max-width: 768px) {
  .home-titles h2 {
    font-size: 18px;
    line-height: 18px;
  }
}
.home-titles h2:after {
  display: none;
}
.home-titles h2 span {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: none;
  display: block;
  margin: 5px 0 0;
  letter-spacing: 2px;
}
@media (max-width: 768px) {
  .home-titles h2 span {
    font-size: 14px;
    line-height: 17px;
  }
}
.main div.home-titles {
  margin: 0 0 25px;
}
div.home-pictos {
  background-color: #ffffff;
  width: 120px;
  height: 120px;
  margin: -30px auto 0;
  padding: 10px;
  border-radius: 100px;
  position: relative;
  text-align: center;
}
div.home-pictos img {
  max-width: 60px;
}
div.home-pictos.hpress {
  margin: 10px auto -30px;
  padding-top: 30px;
}
div.home-pictos.hpress img {
  max-width: 70px;
}
div.home-pictos.fhp {
  margin: 0 auto -30px;
  padding-top: 35px;
}
div.home-pictos.fhp img {
  max-width: 90px;
}
.main div.homeress {
  background-color: rgba(72, 69, 82, 0.05);
  padding-top: 80px;
  padding-bottom: 200px;
  text-align: center;
}
.about {
  position: relative;
  z-index: 1;
  margin-top: -150px;
}
.about div.about-cont {
  margin: 0;
}
.about-cont {
  padding: 70px 70px 30px;
  background-color: #ffffff;
  box-shadow: 3px 5px 35px 0px rgba(63, 77, 83, 0.18);
}
@media (max-width: 1200px) {
  .about-cont {
    padding: 40px 35px 30px;
  }
}
.about div.about-left {
  margin: 0;
}
.about-left {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
@media (max-width: 1200px) {
  .about-left {
    vertical-align: middle;
  }
}
@media (max-width: 640px) {
  .about-left {
    width: 100%;
  }
}
.about-left h2 {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2e2d36;
  margin: 0 0 15px;
}
@media (max-width: 992px) {
  .about-left h2 {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .about-left h2 {
    font-size: 18px;
    line-height: 20px;
  }
}
.about-left h3 {
  font-family: 'Roboto-Light';
  font-weight: 200;
  font-size: 35px;
  line-height: 40px;
  color: #03869a;
  margin: 0 0 15px;
}
@media (max-width: 992px) {
  .about-left h3 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .about-left h3 {
    font-size: 25px;
    line-height: 28px;
  }
}
@media (max-width: 480px) {
  .about-left h3 {
    font-size: 22px;
    line-height: 25px;
  }
}
.about-left p {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2e2d36;
  margin: 0;
}
.about div.about-right {
  margin: 0 0 0 -5px;
}
.about-right {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding: 0 0 0 100px;
}
@media (max-width: 1200px) {
  .about-right {
    padding: 0 0 0 50px;
    vertical-align: middle;
  }
}
@media (max-width: 768px) {
  .about-right {
    padding: 0 0 0 15px;
  }
}
@media (max-width: 640px) {
  .about-right {
    display: none;
  }
}
.about-right img {
  max-width: 100%;
  height: auto;
  display: block;
}
.about div.about-link {
  margin: 10px 0 0;
}
.about-link {
  text-align: center;
}
@media (max-width: 1200px) {
  .about-link {
    margin-top: 20px;
  }
}
